import {Injectable} from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(
   
  ) {
  }
  static register(quantity) {
    let subscribe: any = {
      subscriptions: [],
      index: 0,
      subscribeState: new Subject<any>()
    };
    for (let i=0; i< quantity; i++) {
      subscribe.subscriptions.push(false);
    }
    subscribe.next = () => {
      subscribe.index++;
      subscribe.subscriptions[subscribe.index - 1] = true;
      if (subscribe.subscriptions.every(value => value)) {
        subscribe.subscribeState.next(true);
      }
    }
    subscribe.complete = () => {
      return subscribe.subscribeState.asObservable();
    }
    subscribe.update = (quantity) => {
      for (let i=subscribe.subscriptions.length; i< quantity; i++) {
        subscribe.subscriptions.push(false);
      }
    }
    return subscribe;
  }
}
