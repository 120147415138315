import { Actions } from '@ngxs/store';
import { Action } from './action.model';
import { tap, filter, map } from 'rxjs/operators';
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream';

export abstract class BaseQueries {
  constructor(
    protected actions$: Actions
  ) { }

  ofActionSuccessful$(actions: Action[]) {
    return this.actions$.pipe(
      filter( actionContext => this.actionFilter(ActionStatus.Successful, actions, actionContext)),
      map(result => result.action)
    );
  }

  ofActionErrored$(...actions: any[]) {
    return this.actions$.pipe(
      filter(actionContext => this.actionFilter(ActionStatus.Errored, actions, actionContext)),
      map(result => {
       const { error, action } = result;
       return { error, action };
      })
    );
  }

  private actionFilter(status: string, actions: any[], actionContext: ActionContext): boolean {
    return actionContext.status === status &&
    actions.findIndex( act => act.prototype.constructor.type === actionContext.action.constructor.type) !== -1;
  }
}
