import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Action } from './action.model';

@Injectable({ providedIn: 'root' })
export class Dispatcher {
  readonly fire = (action: Action | Action[]) => this.store.dispatch(action);

  constructor(
    private store: Store
  ) { }
}
